import { CategoriesStateType, CategoriesActionType } from "./types";
import {
  CATEGORIES_LOAD_FAILURE,
  CATEGORIES_LOAD_SUCCESS,
  CATEGORIES_RESET_ERRORS,
  CATEGORIES_RESET_MESSAGES,
  CATEGORIES_SET_ERRORS,
  CATEGORIES_SET_MESSAGES,
  CATEGORY_LOAD_FAILURE,
  CATEGORY_LOAD_SUCCESS,
  CATEGORY_RESET,
} from "../actions/categories";

const initialState: CategoriesStateType = {
  categories: [],
  category: null,
  messages: [],
  errors: null,
};

export default function categories(
  state = initialState,
  action: CategoriesActionType
) {
  switch (action.type) {
    case CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        categories: action.categories,
      };

    case CATEGORY_LOAD_SUCCESS:
      return {
        ...state,
        category: action.category,
      };

    case CATEGORY_RESET:
      return {
        ...state,
        category: null,
      };

    case CATEGORIES_RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    case CATEGORIES_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case CATEGORIES_SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case CATEGORIES_LOAD_FAILURE:
    case CATEGORY_LOAD_FAILURE:
    case CATEGORIES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
}
