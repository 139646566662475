import { AuthActionType, AuthStateType } from "./types";
import {
  AUTH_FAILED,
  AUTH_FROM,
  AUTH_LOADED,
  AUTH_LOADING,
  AUTH_LOGOUT,
  AUTH_REGISTERED,
  AUTH_RESET_ERRORS,
  AUTH_RESET_MESSAGES,
  AUTH_SET_ERRORS,
  AUTH_SET_MESSAGES,
} from "../actions/auth";

const initialState: AuthStateType = {
  status: "pending",
  user: null,
  token: localStorage.getItem("token"),
  from: null,
  messages: [],
  errors: null,
};

export default function auth(state = initialState, action: AuthActionType) {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        status: "loading",
        errors: null,
      };

    case AUTH_LOADED:
      action.token && localStorage.setItem("token", action.token);
      return {
        ...state,
        status: "authenticated",
        user: action.user,
        token: action.token || state.token,
        errors: null,
      };

    case AUTH_LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        status: "logout",
        user: null,
        token: null,
        errors: null,
      };

    case AUTH_FAILED:
      localStorage.removeItem("token");
      return {
        ...state,
        status: "failed",
        user: null,
        token: null,
        errors: action.errors || null,
      };

    case AUTH_REGISTERED:
      return {
        ...state,
        messages: action.messages,
      };

    case AUTH_FROM:
      return {
        ...state,
        from: action.from,
      };

    case AUTH_RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    case AUTH_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case AUTH_SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case AUTH_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
}
