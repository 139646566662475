import React from "react";
import { ExcelStateType, StateType } from "../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { downloadExcel } from "../actions/excel";
import { useInterval } from "../utils";

type Props = {
  excel: ExcelStateType;
  downloadExcel: () => void;
};

function ExcelDownloadChecker(props: Props) {
  const { excel, downloadExcel } = props;

  useInterval(
    () => {
      downloadExcel();
    },
    excel.excel ? 2000 : null
  );

  return <></>;
}

function mapStateToProps(state: StateType) {
  return {
    excel: state.excel,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      downloadExcel,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelDownloadChecker);
