import { ExcelActionType, ExcelStateType } from "./types";
import { EXCEL_SET, EXCEL_MESSAGES, EXCEL_ERRORS } from "../actions/excel";

const initialState: ExcelStateType = {
  excel: undefined,
  messages: [],
  errors: null,
};

export default function excel(state = initialState, action: ExcelActionType) {
  switch (action.type) {
    case EXCEL_SET:
      return {
        ...state,
        excel: action.excel,
      };

    case EXCEL_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case EXCEL_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
}
