import React from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import styled from "styled-components";
import Check from "../assets/images/check.svg";

type ConfirmDialogProps = {
  open: boolean;
  messages: string[];
  onClose: () => void;
  onCancel: () => void;
};

const DialogContentBody = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(4)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 1.5rem;
      text-align: center;
    }
  `}
`;

const DialogActions = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > button {
      font-size: 1.25rem;
      height: 40px;
      flex: 1 1 50%;
      margin: ${theme.spacing(2)}px;
    }
  `}
`;

export function ConfirmDialog(props: ConfirmDialogProps) {
  const { open, messages, onClose, onCancel } = props;
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentBody>
          {messages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </DialogContentBody>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => onClose()}
          >
            확인
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => onCancel()}
          >
            취소
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export function Spacer() {
  return <div style={{ flex: "1 1 auto" }} />;
}

const CheckboxWrapper = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    & > span {
      font-size: 1rem;
      line-height: 1rem;
      margin-right: ${theme.spacing(2)}px;
    }
    & > div {
      margin: 4px 0;
      position: relative;
      width: ${theme.spacing(4)}px;
      height: ${theme.spacing(4)}px;
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.25);
      & > img {
        position: absolute;
        top: -4px;
        left: -2px;
        height: ${theme.spacing(4)}px;
      }
    }
  `}
`;

export const Checkbox = ({
  label,
  checked,
  onChange,
}: {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <CheckboxWrapper onClick={() => onChange(!checked)}>
      {label && <span>{label}</span>}
      <div>{checked && <img src={Check} alt="체크" />}</div>
    </CheckboxWrapper>
  );
};
