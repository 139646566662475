import { AdminStateType, AdminActionType } from "./types";
import {
  ADMIN_ERRORS,
  ADMIN_MESSAGES,
} from "../actions/admin";

const initialState: AdminStateType = {
  messages: [],
  errors: null,
};

export default function admin(state = initialState, action: AdminActionType) {
  switch (action.type) {
    case ADMIN_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case ADMIN_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
}
