import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { MessagesType, StateType } from "../reducers/types";
import { resetMessages as resetAuthMessages } from "../actions/auth";
import { resetMessages as resetCategoriesMessages } from "../actions/categories";
import { resetMessages as resetRecordsMessages } from "../actions/records";
import { resetMessages as resetResourcesMessages } from "../actions/resources";
import { resetMessages as resetExcelMessages } from "../actions/excel";

type Props = {
  authMessages: MessagesType;
  adminMessages: MessagesType;
  categoriesMessages: MessagesType;
  recordsMessages: MessagesType;
  resourcesMessages: MessagesType;
  excelMessages: MessagesType;
  resetAuthMessages: () => void;
  resetCategoriesMessages: () => void;
  resetRecordsMessages: () => void;
  resetResourcesMessages: () => void;
  resetExcelMessages: () => void;
};

function Messages(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    authMessages,
    adminMessages,
    categoriesMessages,
    recordsMessages,
    resourcesMessages,
    excelMessages,
    resetAuthMessages,
    resetCategoriesMessages,
    resetRecordsMessages,
    resetResourcesMessages,
    resetExcelMessages,
  } = props;

  const handleMessages = (messages: MessagesType) => {
    messages.forEach((message) => {
      enqueueSnackbar(message.message, { variant: message.variant as any });
    });
  };

  React.useEffect(() => {
    if (authMessages.length > 0) {
      handleMessages(authMessages);
      resetAuthMessages();
    }
  }, [authMessages]);

  React.useEffect(() => {
    if (adminMessages.length > 0) {
      handleMessages(adminMessages);
    }
  }, [adminMessages]);

  React.useEffect(() => {
    if (categoriesMessages.length > 0) {
      handleMessages(categoriesMessages);
      resetCategoriesMessages();
    }
  }, [categoriesMessages]);

  React.useEffect(() => {
    if (recordsMessages.length > 0) {
      handleMessages(recordsMessages);
      resetRecordsMessages();
    }
  }, [recordsMessages]);

  React.useEffect(() => {
    if (resourcesMessages.length > 0) {
      handleMessages(resourcesMessages);
      resetResourcesMessages();
    }
  }, [resourcesMessages]);

  React.useEffect(() => {
    if (excelMessages.length > 0) {
      handleMessages(excelMessages);
      resetExcelMessages();
    }
  }, [excelMessages]);

  return <></>;
}

function mapStateToProps(state: StateType) {
  return {
    adminMessages: state.admin.messages,
    categoriesMessages: state.categories.messages,
    authMessages: state.auth.messages,
    recordsMessages: state.records.messages,
    resourcesMessages: state.resources.messages,
    excelMessages: state.excel.messages,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAuthMessages,
      resetCategoriesMessages,
      resetRecordsMessages,
      resetResourcesMessages,
      resetExcelMessages,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
