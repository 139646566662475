import axios from "axios";
import { GetState, Dispatch, UserType } from "../reducers/types";
import { loadUsers } from "./users";

export const ADMIN_MESSAGES = "ADMIN_MESSAGES";
export const ADMIN_ERRORS = "ADMIN_ERRORS";

export const updateUser = (user: UserType) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: ADMIN_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .patch(
        `/api/admin/users/${user.id}/`,
        {
          email: user.email,
          nickname: user.nickname,
          isActive: user.isActive,
          isStaff: user.isStaff,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({
          type: ADMIN_MESSAGES,
          messages: [
            {
              variant: "success",
              message: `유저(${user.nickname})의 정보가 수정되었습니다.`,
            },
          ],
        });

        // @ts-ignore
        dispatch(loadUsers());
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: ADMIN_ERRORS, errors: error.response.data });
        } else {
          dispatch({
            type: ADMIN_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }

        // @ts-ignore
        dispatch(loadUsers());
      });
  };
};

export const resetPassword = (email: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { user, token } = getState().auth;
    if (!user) {
      dispatch({
        type: ADMIN_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
    }

    axios
      .post(
        `/api/auth/reset-password/`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: ADMIN_MESSAGES,
          messages: [
            {
              variant: "success",
              message: response.data.detail,
            },
          ],
        });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: ADMIN_ERRORS, errors: error.response.data });
        } else {
          dispatch({
            type: ADMIN_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};
