import axios from "axios";
import { GetState, Dispatch } from "../reducers/types";

export const USERS_LOADED = "USERS_LOADED";
export const USERS_LOAD_FAILED = "USERS_LOAD_FAILED";

export const loadUsers = (page = 1, pageSize = 100) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: USERS_LOAD_FAILED });
      return;
    }

    axios
      .get(`/api/admin/users/?page_size=${pageSize}&page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: USERS_LOADED,
          users: response.data.results,
          count: response.data.count,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: USERS_LOAD_FAILED, errors: error.response.data });
        } else {
          dispatch({
            type: USERS_LOAD_FAILED,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};
