import React from "react";
import { AuthStateType, RecordType, StateType } from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";

const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
    & .MuiPaper-root {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
    & .MuiTablePagination-root {
      border-bottom: none;
    }
  `}
`;

type Props = {
  auth: AuthStateType;
};

function MyRecordsView(props: Props) {
  const { auth } = props;

  return (
    <Root>
      <MaterialTable
        columns={[
          // { title: "자산분류", field: "category" },
          { title: "자산명", field: "name" },
          { title: "실행자", field: "executor" },
          { title: "상태", field: "actionDisplay" },
          { title: "수정 날짜", field: "created" },
          { title: "기존 이용자", field: "prev" },
          { title: "변경 이용자", field: "next" },
        ]}
        title="내가 관여한 자산 이력"
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          padding: "dense",
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                `/api/my/records/?page=${query.page + 1}&page_size=${
                  query.pageSize
                }&search=${query.search}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${auth.token}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data.results.map((record: RecordType) => {
                  return {
                    ...record,
                    category: record.resource.categoryName,
                    name: record.resource.name,
                    executor: record.executor.nickname,
                    created: moment(record.created).format("YY-MM-DD"),
                    prev: record.prev?.nickname,
                    next: record.next?.nickname,
                  };
                });
                resolve({
                  data,
                  page: query.page,
                  totalCount: response.data.count,
                });
              })
              .catch(function () {
                reject();
              });
          })
        }
      />
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRecordsView);
