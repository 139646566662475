import React from "react";
import { Redirect, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import Login from "./components/Login";
import Main from "./components/Main";
import { AuthStateType, StateType } from "./reducers/types";
import { loadUser, setFrom } from "./actions/auth";
import Register from "./components/Register";
import Resource from "./components/Resource";

export const COMPONENTS = {
  LOGIN: {
    isPrivate: false,
    path: "/login",
    component: Login,
  },
  REGISTER: {
    isPrivate: false,
    path: "/register",
    component: Register,
  },
  MAIN: {
    isPrivate: true,
    path: "/",
    component: Main,
  },
  RESOURCE: {
    isPrivate: true,
    path: "/resources/:id",
    component: Resource,
  },
};

type Props = {
  auth: AuthStateType;
  loadUser: () => void;
  setFrom: (from: string | null) => void;
};

function Routes(props: Props) {
  const { auth, loadUser, setFrom } = props;
  const isNotAuthenticated =
    auth.status !== "pending" && auth.status !== "loading" && !auth.user;

  React.useEffect(() => {
    loadUser();
  }, []);

  if (
    isNotAuthenticated &&
    window.location.pathname !== COMPONENTS.LOGIN.path &&
    window.location.pathname !== COMPONENTS.REGISTER.path
  ) {
    setFrom(window.location.href);
  }

  return (
    <TransitionGroup>
      {Object.values(COMPONENTS).map((component) => {
        const { isPrivate, path, component: Component } = component;
        return isPrivate ? (
          <Route exact key={path} path={path}>
            {isNotAuthenticated
              ? ({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={300}
                    classNames="fadein"
                    unmountOnExit
                  >
                    <Redirect to={COMPONENTS.LOGIN.path} />
                  </CSSTransition>
                )
              : ({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={300}
                    classNames="fadein"
                    unmountOnExit
                  >
                    <Component />
                  </CSSTransition>
                )}
          </Route>
        ) : (
          <Route exact key={path} path={path}>
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="fadein"
                unmountOnExit
              >
                <Component />
              </CSSTransition>
            )}
          </Route>
        );
      })}
    </TransitionGroup>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadUser,
      setFrom,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
