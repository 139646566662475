import React from "react";
import { useHistory, useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Button, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import Hashids from "hashids";
import styled from "styled-components";
import QRCode from "qrcode";
import {
  AuthStateType,
  ResourcesStateType,
  StateType,
} from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { doCommand, loadResource } from "../../actions/resources";
import { getStatus } from "../../utils";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment";

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const Content = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > img {
      width: 300px;
      height: 300px;
      margin: ${theme.spacing(3)}px;
    }
    & > div > h3 {
      font-size: 2rem;
    }
    & > div > h4 {
      font-size: 1.125rem;
      font-weight: 400;
      color: #3498db;
    }
    & > .MuiTextField-root {
      width: 240px;
      & .MuiOutlinedInput-input {
        padding: 14.5px 14px;
        padding-left: 0;
      }
    }
    ${theme.breakpoints.down(400)} {
      & > img {
        width: 160px;
        height: 160px;
        margin: 24px 0;
      }
    }
  `}
`;

const Buttons = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(2)}px;
    ${theme.breakpoints.down(600)} {
      flex-direction: column;
    }
  `}
`;

interface MyButtonProps {
  color: "red" | "blue";
}

const useStyles = makeStyles({
  root: {
    background: (props: MyButtonProps) =>
      props.color === "red"
        ? "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
        : "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: (props: MyButtonProps) =>
      props.color === "red"
        ? "0 3px 5px 2px rgba(255, 105, 135, .3)"
        : "0 3px 5px 2px rgba(33, 203, 243, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    margin: 8,
    width: 240,
    fontSize: "1rem",
    "&:disabled": {
      color: "white",
      opacity: 0.4,
    },
  },
});

function MyButton(
  props: MyButtonProps & Omit<MuiButtonProps, keyof MyButtonProps>
) {
  const { color, ...other } = props;
  const classes = useStyles(props);
  return <Button className={classes.root} {...other} />;
}

type Props = {
  auth: AuthStateType;
  resources: ResourcesStateType;
  loadResource: (id: number) => void;
  doCommand: (
    resourceId: number,
    command: "대여" | "반납",
    due?: string
  ) => void;
};

function Resource(props: Props) {
  const history = useHistory();
  const { id } = useParams();
  const { auth, resources, loadResource, doCommand } = props;
  const [decoded, setDecoded] = React.useState(0);
  const [url, setUrl] = React.useState("");
  const [due, setDue] = React.useState<Moment | null>(null);

  const { resource } = resources;

  React.useEffect(() => {
    const hashids = new Hashids("", 16);
    setDecoded(hashids.decode(id)[0] as number);
    QRCode.toDataURL(`${window.location.origin}/resources/${id}`, function (
      _,
      url
    ) {
      setUrl(url);
    });
  }, [id]);

  React.useEffect(() => {
    if (decoded > 0) {
      loadResource(decoded);
    }
  }, [decoded]);

  React.useEffect(() => {
    if (resource) {
      setDue(null);
    }
  }, [resource]);

  return (
    <Root>
      <Content>
        <img src={url} alt="qrcode" />

        <div style={{ height: 150, textAlign: "center" }}>
          <h4>{resource?.categoryName}</h4>
          <h3>{resource?.name}</h3>
          {resource !== null && (
            <p>
              <strong>상태 :</strong>{" "}
              <span
                style={{
                  color: resource?.isAvailable ? "#27ae60" : "#e74c3c",
                }}
              >
                {getStatus(resource)}
              </span>
            </p>
          )}
          {resource !== null && (
            <p>
              <strong>대여자 :</strong>{" "}
              {resource?.user ? resource.user.nickname : "없음"}
            </p>
          )}
          {resource !== null && (
            <p>
              <strong>시료 정보:</strong> {resource.comment}
            </p>
          )}
          {resource !== null && resource.due && (
            <p>
              <strong>반납 예정일:</strong>{" "}
              {moment(resource.due).format("YYYY.MM.DD")}
            </p>
          )}
        </div>

        <Buttons>
          <MyButton
            disabled={
              resource === null ||
              !resource.isAvailable ||
              due === null ||
              (auth.user !== null && resource.user?.id === auth.user.id)
            }
            color="blue"
            onClick={() => {
              if (resource?.id) {
                doCommand(
                  resource.id,
                  "대여",
                  due ? due.format("YYYY-MM-DD") : undefined
                );
              }
            }}
          >
            대여
          </MyButton>
          <MyButton
            disabled={resource === null || !auth.user?.isStaff}
            color="red"
            onClick={() => {
              if (resource?.id) {
                doCommand(resource.id, "반납");
              }
            }}
          >
            반납
          </MyButton>
        </Buttons>
        <KeyboardDatePicker
          autoOk
          disablePast
          clearable
          inputVariant="outlined"
          label="반납 예정일"
          InputAdornmentProps={{ position: "start" }}
          value={due}
          onChange={(date) => setDue(date)}
          format="YYYY.MM.DD"
        />
        <Button
          style={{ height: 48, width: 200 }}
          onClick={() => {
            history.push("/");
          }}
        >
          자산 관리 시스템으로 가기
        </Button>
      </Content>
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
    resources: state.resources,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadResource,
      doCommand,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
