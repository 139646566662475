import React from "react";
import { AuthStateType, RecordType, StateType } from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import {
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Excel from "../../assets/images/excel.png";
import { createExcel } from "../../actions/excel";

const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
    & .MuiPaper-root {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
    & .MuiTablePagination-root {
      border-bottom: none;
    }
  `}
`;

const Action = styled.div`
  margin-top: 16px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & > .MuiFormControl-root {
    background-color: white;
    margin-right: 8px;
  }
  & > .MuiFab-root {
    background-color: white;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    & img {
      width: 24px;
      height: 24px;
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.7);
    }
  }
`;

type Props = {
  auth: AuthStateType;
  createExcel: (period: string) => void;
};

function AllRecordsView(props: Props) {
  const { auth, createExcel } = props;
  const [period, setPeriod] = React.useState("day");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriod(event.target.value as string);
  };

  return (
    <Root>
      <MaterialTable
        columns={[
          // { title: "자산분류", field: "category" },
          { title: "자산명", field: "name" },
          { title: "실행자", field: "executor" },
          { title: "상태", field: "actionDisplay" },
          { title: "수정 날짜", field: "created" },
          { title: "기존 이용자", field: "prev" },
          { title: "변경 이용자", field: "next" },
        ]}
        title="전체 자산 이력"
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          padding: "dense",
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                `/api/admin/records/?page=${query.page + 1}&page_size=${
                  query.pageSize
                }&search=${query.search}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${auth.token}`,
                  },
                }
              )
              .then(function (response) {
                const data = response.data.results.map((record: RecordType) => {
                  return {
                    ...record,
                    category: record.resource.categoryName,
                    name: record.resource.name,
                    executor: record.executor.nickname,
                    created: moment(record.created).format("YY-MM-DD"),
                    prev: record.prev?.nickname,
                    next: record.next?.nickname,
                  };
                });
                resolve({
                  data,
                  page: query.page,
                  totalCount: response.data.count,
                });
              })
              .catch(function () {
                reject();
              });
          })
        }
      />

      <Action>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">기간</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={period}
            onChange={handleChange}
            label="Age"
          >
            <MenuItem value={"day"}>일일</MenuItem>
            <MenuItem value={"week"}>주간</MenuItem>
            <MenuItem value={"month"}>월간</MenuItem>
            <MenuItem value={"year"}>연간</MenuItem>
            <MenuItem value={"all"}>전체</MenuItem>
          </Select>
        </FormControl>

        <Fab
          aria-label="download"
          onClick={() => {
            createExcel(period);
          }}
        >
          <img src={Excel} alt="excel" />
        </Fab>
      </Action>
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      createExcel,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRecordsView);
