import axios from "axios";
import {
  GetState,
  Dispatch,
  MessagesType,
  ErrorsType,
} from "../reducers/types";

export const RECORDS_RESET_MESSAGES = "RECORDS_RESET_MESSAGES";
export const RECORDS_RESET_ERRORS = "RECORDS_RESET_ERRORS";
export const RECORDS_SET_MESSAGES = "RECORDS_SET_MESSAGES";
export const RECORDS_SET_ERRORS = "RECORDS_SET_ERRORS";
export const RECORDS_LOAD_SUCCESS = "RECORDS_LOAD_SUCCESS";
export const RECORDS_LOAD_FAILURE = "RECORDS_LOAD_FAILURE";
export const RECORDS_RESET = "RECORDS_RESET";

export const resetMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_RESET_MESSAGES });
  };
};

export const resetErrors = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_RESET_ERRORS });
  };
};

export const setMessages = (messages: MessagesType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_SET_MESSAGES, messages });
  };
};

export const setErrors = (errors: ErrorsType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_SET_ERRORS, errors });
  };
};

export const resetRecords = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RECORDS_RESET });
  };
};

export const loadRecords = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: RECORDS_LOAD_FAILURE });
      return;
    }

    axios
      .get(`/api/admin/records/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: RECORDS_LOAD_SUCCESS,
          resources: response.data,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: RECORDS_LOAD_FAILURE,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: RECORDS_LOAD_FAILURE,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const loadMyRecords = (page = 1, pageSize = 100) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: RECORDS_LOAD_FAILURE });
      return;
    }

    axios
      .get(`/api/my/records/?page=${page}&page_size=${pageSize}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: RECORDS_LOAD_SUCCESS,
          resources: response.data,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: RECORDS_LOAD_FAILURE,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: RECORDS_LOAD_FAILURE,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};
