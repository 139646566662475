import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import styled from "styled-components";

const PasswordTextField = styled(TextField)`
  ${() => `
    & input {
      font-family: 'Roboto', sans-serif;
      color: #000;
    }
  `}
`;

type Props = {
  open: boolean;
  email?: string;
  nickname?: string;
  isEmailNotificationEnable?: boolean;
  onCancel: () => void;
  onClose: (
    nickname: string,
    isEmailNotificationEnable: boolean,
    oldPassword?: string,
    newPassword?: string
  ) => void;
};

export default function ProfileDialog(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onCancel,
    onClose,
    email,
    nickname: nicknameProp,
    isEmailNotificationEnable: isEmailNotificationEnableProp,
  } = props;
  const [nickname, setNickname] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [
    isEmailNotificationEnable,
    setIsEmailNotificationEnable,
  ] = React.useState(false);

  React.useEffect(() => {
    if (open && nicknameProp) {
      setNickname(nicknameProp);
      setOldPassword("");
      setNewPassword("");
      setConfirm("");
      setIsEmailNotificationEnable(!!isEmailNotificationEnableProp);
    }
  }, [open]);

  const handleClose = React.useCallback(() => {
    if (nickname === "") {
      enqueueSnackbar("닉네임을 지정해야 합니다", {
        variant: "error",
      });
      return;
    }

    if (newPassword !== "" || confirm !== "") {
      if (oldPassword === "") {
        enqueueSnackbar("기존 비밀번호를 확인해주세요", {
          variant: "error",
        });
        return;
      }
      if (newPassword !== confirm) {
        enqueueSnackbar("확인 패스워드가 일치하지 않습니다.", {
          variant: "error",
        });
        return;
      }
      onClose(nickname, isEmailNotificationEnable, oldPassword, newPassword);
      return;
    }
    onClose(nickname, isEmailNotificationEnable);
  }, [nickname, isEmailNotificationEnable, oldPassword, newPassword, confirm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmailNotificationEnable(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogContent>
        <TextField
          disabled
          variant="outlined"
          size="small"
          margin="dense"
          label="이메일"
          type="email"
          value={email}
          fullWidth
        />
        <TextField
          autoFocus
          variant="outlined"
          size="small"
          margin="dense"
          label="닉네임"
          type="text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isEmailNotificationEnable}
              onChange={handleChange}
              name="checkbox"
              color="primary"
            />
          }
          label="이메일 알림"
        />
        <PasswordTextField
          variant="outlined"
          size="small"
          margin="dense"
          label="기존 패스워드"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          fullWidth
        />
        <PasswordTextField
          variant="outlined"
          size="small"
          margin="dense"
          label="새 패스워드"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
        />
        <PasswordTextField
          variant="outlined"
          size="small"
          margin="dense"
          label="패스워드 확인"
          type="password"
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={onCancel}>
          취소
        </Button>
        <Button disableElevation onClick={handleClose}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
