import { ResourcesActionType, ResourcesStateType } from "./types";
import {
  RESOURCE_LOAD_FAILURE,
  RESOURCE_LOAD_SUCCESS,
  RESOURCE_RESET,
  RESOURCES_RESET_ERRORS,
  RESOURCES_RESET_MESSAGES,
  RESOURCES_SET_ERRORS,
  RESOURCES_SET_MESSAGES,
} from "../actions/resources";

const initialState: ResourcesStateType = {
  resource: null,
  messages: [],
  errors: null,
};

export default function resources(
  state = initialState,
  action: ResourcesActionType
) {
  switch (action.type) {
    case RESOURCE_LOAD_SUCCESS:
      return {
        ...state,
        resource: action.resource,
      };

    case RESOURCE_RESET:
      return {
        ...state,
        resource: null,
      };

    case RESOURCES_RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    case RESOURCES_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case RESOURCES_SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case RESOURCE_LOAD_FAILURE:
    case RESOURCES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
}
