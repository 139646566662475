import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { ErrorsType, StateType } from "../reducers/types";
import { resetErrors as resetAuthErrors } from "../actions/auth";
import { resetErrors as resetCategoriesErrors } from "../actions/categories";
import { resetErrors as resetRecordsErrors } from "../actions/records";
import { resetErrors as resetResourcesErrors } from "../actions/resources";

type Props = {
  authErrors: ErrorsType;
  usersErrors: ErrorsType;
  adminErrors: ErrorsType;
  categoriesErrors: ErrorsType;
  recordsErrors: ErrorsType;
  resourcesErrors: ErrorsType;
  resetAuthErrors: () => void;
  resetCategoriesErrors: () => void;
  resetRecordsErrors: () => void;
  resetResourcesErrors: () => void;
};

const translations = {};

function Errors(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    authErrors,
    usersErrors,
    adminErrors,
    categoriesErrors,
    recordsErrors,
    resourcesErrors,
    resetAuthErrors,
    resetCategoriesErrors,
    resetRecordsErrors,
    resetResourcesErrors,
  } = props;

  const handleErrors = (errors: any) => {
    if (errors) {
      if (typeof errors === "string") {
        enqueueSnackbar(errors, { variant: "error" });
      } else {
        Object.entries(errors).forEach(([key, value]) => {
          if (Object.keys(translations).includes(key)) {
            enqueueSnackbar((translations as any)[key], { variant: "error" });
          } else if (key === "detail" || key === "nonFieldErrors") {
            enqueueSnackbar(value as string, { variant: "error" });
          } else {
            enqueueSnackbar(`[${key}]: ${value}`, { variant: "error" });
          }
        });
      }
    }
  };

  React.useEffect(() => {
    if (authErrors) {
      handleErrors(authErrors);
      resetAuthErrors();
    }
  }, [authErrors]);

  React.useEffect(() => {
    handleErrors(usersErrors);
  }, [usersErrors]);

  React.useEffect(() => {
    handleErrors(adminErrors);
  }, [adminErrors]);

  React.useEffect(() => {
    if (categoriesErrors) {
      handleErrors(categoriesErrors);
      resetCategoriesErrors();
    }
  }, [categoriesErrors]);

  React.useEffect(() => {
    if (recordsErrors) {
      handleErrors(recordsErrors);
      resetRecordsErrors();
    }
  }, [recordsErrors]);

  React.useEffect(() => {
    if (resourcesErrors) {
      handleErrors(resourcesErrors);
      resetResourcesErrors();
    }
  }, [resourcesErrors]);

  return <></>;
}

function mapStateToProps(state: StateType) {
  return {
    authErrors: state.auth.errors,
    usersErrors: state.users.errors,
    adminErrors: state.admin.errors,
    categoriesErrors: state.categories.errors,
    recordsErrors: state.records.errors,
    resourcesErrors: state.resources.errors,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAuthErrors,
      resetCategoriesErrors,
      resetRecordsErrors,
      resetResourcesErrors,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
