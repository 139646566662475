import { UsersStateType, UsersActionType } from "./types";
import { USERS_LOADED, USERS_LOAD_FAILED } from "../actions/users";

const initialState: UsersStateType = {
  users: [],
  count: 0,
  errors: null,
};

export default function users(state = initialState, action: UsersActionType) {
  switch (action.type) {
    case USERS_LOADED:
      return {
        ...state,
        users: action.users,
        count: action.count,
        errors: null,
      };

    case USERS_LOAD_FAILED:
      return {
        ...state,
        users: [],
        count: 0,
        errors: action.errors,
      };

    default:
      return state;
  }
}
