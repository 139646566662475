import React from "react";
import styled from "styled-components";
import Background from "../../assets/images/background.gif";
import {
  Paper as MuiPaper,
  Button as MuiButton,
  InputAdornment,
  Icon,
  TextField,
} from "@material-ui/core";
import { AuthStateType, StateType } from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { register } from "../../actions/auth";
import { connect } from "react-redux";
import { COMPONENTS } from "../../Routes";
import { useHistory } from "react-router";
import Animation from "../../assets/images/animation.gif";
import Wave from "../../assets/images/wave.svg";
import Logo from "../../assets/images/hideep_full_logo.svg";
import { useSnackbar } from "notistack";

const Root = styled.div`
  ${() => `
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("${Background}") repeat;
  `}
`;

const Paper = styled(MuiPaper)`
  ${({ theme }) => `
    overflow: hidden;
    border-radius: 16px;
    width: calc(100vw - 48px);
    height: 700px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    ${theme.breakpoints.up(1280)} {
      width: 1200px;
    }
    @media screen and (max-height: 748px) {
      height: calc(100vh - 48px);
      height: calc(var(--vh, 1vh) * 100 - 48px);
    }
  `}
`;

const Left = styled.div`
  ${({ theme }) => `
    position: relative;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing(6, 0)};
    text-align: right;
    background-color: #edf7f4;
    & > div:last-child {
      width: auto !important;
      height: auto !important;
    }
    ${theme.breakpoints.down(1000)} {
      display: none;
    }
  `}
`;

const Right = styled.div`
  ${({ theme }) => `
    position: relative;
    flex: 0 0 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > .MuiFormControl-root {
      width: 230px;
    }
    ${theme.breakpoints.down(1000)} {
      flex: 1 1 auto;
    }
  `}
`;

const Title = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    & > img {
      display: none;
      height: 64px;
      margin-right: ${theme.spacing(2)}px;
    }
    & > h1 {
      vertical-align: middle;
      color: ${theme.palette.primary.dark};
      font-size: 4rem;
      margin-right: 200px;
    }
    z-index: 1;
  `}
`;

const Subtitle = styled.span`
  ${({ theme }) => `
    color: ${theme.palette.primary.dark};
    font-size: 3.25rem;
    font-weight: 300;
    z-index: 1;
  `}
`;

const SmallTitle = styled.div`
  ${({ theme }) => `
    display: none;
    width: 240px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: ${theme.spacing(0, 0, 4)};
    & > img {
      width: 200px;
    }
    ${theme.breakpoints.down(1000)} {
      display: flex;
    }
    @media screen and (max-height: 400px) {
      width: 120px;
      display: none;
    }
  `}
`;

const Button = styled(MuiButton)`
  ${() => `
    margin: 4px;
    width: 230px;
    height: 40px;
    border-radius: 6px;
    font-size: 1.125rem;
  `}
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const LowerBg = styled.img`
  ${({ theme }) => `
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    ${theme.breakpoints.down(1000)} {
      display: flex;
    }
    @media screen and (max-height: 400px) {
      display: none;
    }
  `}
`;

type Props = {
  auth: AuthStateType;
  register: (email: string, nickname: string, password: string) => void;
};

function Register(props: Props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { auth, register } = props;
  const [email, setEmail] = React.useState("");
  const [nickname, setNickname] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");

  React.useEffect(() => {
    if (auth.status === "authenticated") {
      history.push(COMPONENTS.MAIN.path);
    }
  }, [auth.status]);

  const submit = () => {
    if (!email) {
      enqueueSnackbar("이메일을 입력해주세요.", { variant: "error" });
      return;
    }
    if (!nickname) {
      enqueueSnackbar("닉네임을 입력해주세요.", { variant: "error" });
      return;
    }
    if (!password || !confirm) {
      enqueueSnackbar("비밀번호를 입력해주세요.", { variant: "error" });
      return;
    }
    if (password !== confirm) {
      enqueueSnackbar("확인 비밀번호가 일치하지 않습니다..", {
        variant: "error",
      });
      return;
    }
    register(email, nickname, password);
    history.push(COMPONENTS.MAIN.path);
  };

  return (
    <Root>
      <Paper elevation={6}>
        <Left>
          <Title>
            <h1>HiDeep</h1>
          </Title>
          <Subtitle>
            자산 <strong>관리</strong> 시스템
          </Subtitle>

          <Image src={Animation} alt="애니메이션" />
        </Left>

        <Right>
          <SmallTitle>
            <img src={Logo} alt="로고" />
          </SmallTitle>
          <TextField
            label="이메일"
            autoFocus
            variant="outlined"
            margin="dense"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>mail</Icon>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="닉네임"
            variant="outlined"
            margin="dense"
            size="small"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>account_circle</Icon>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="비밀번호"
            type="password"
            variant="outlined"
            margin="dense"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>lock</Icon>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="비밀번호 확인"
            type="password"
            variant="outlined"
            margin="dense"
            size="small"
            value={confirm}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submit();
              }
            }}
            onChange={(e) => setConfirm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>lock</Icon>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" disableElevation onClick={() => submit()}>
            회원가입
          </Button>

          <LowerBg src={Wave} alt="하단배경" />
        </Right>
      </Paper>
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      register,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
