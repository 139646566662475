import axios from "axios";
import {
  GetState,
  Dispatch,
  MessagesType,
  ErrorsType,
} from "../reducers/types";

export const CATEGORIES_RESET_MESSAGES = "CATEGORIES_RESET_MESSAGES";
export const CATEGORIES_RESET_ERRORS = "CATEGORIES_RESET_ERRORS";
export const CATEGORIES_SET_MESSAGES = "CATEGORIES_SET_MESSAGES";
export const CATEGORIES_SET_ERRORS = "CATEGORIES_SET_ERRORS";
export const CATEGORIES_LOAD_SUCCESS = "CATEGORIES_LOAD_SUCCESS";
export const CATEGORIES_LOAD_FAILURE = "CATEGORIES_LOAD_FAILURE";
export const CATEGORY_LOAD_SUCCESS = "CATEGORY_LOAD_SUCCESS";
export const CATEGORY_LOAD_FAILURE = "CATEGORY_LOAD_FAILURE";
export const CATEGORY_RESET = "CATEGORY_RESET";

export const resetMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CATEGORIES_RESET_MESSAGES });
  };
};

export const resetErrors = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CATEGORIES_RESET_ERRORS });
  };
};

export const setMessages = (messages: MessagesType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CATEGORIES_SET_MESSAGES, messages });
  };
};

export const setErrors = (errors: ErrorsType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CATEGORIES_SET_ERRORS, errors });
  };
};

export const loadCategories = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: CATEGORIES_LOAD_FAILURE });
      return;
    }

    axios
      .get(`/api/ro/categories/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: CATEGORIES_LOAD_SUCCESS,
          categories: response.data,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_LOAD_FAILURE,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_LOAD_FAILURE,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const loadCategory = (categoryId: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: CATEGORY_LOAD_FAILURE });
      return;
    }

    axios
      .get(`/api/admin/categories/${categoryId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: CATEGORY_LOAD_SUCCESS,
          category: response.data,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORY_LOAD_FAILURE,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORY_LOAD_FAILURE,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const resetCategory = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CATEGORY_RESET });
  };
};

export const addCategory = (name: string, parentId?: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .post(
        `/api/admin/categories/`,
        {
          name,
          parent: parentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "분류가 추가되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};

export const editCategory = (
  categoryId: number,
  name: string,
  parentId?: number
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .patch(
        `/api/admin/categories/${categoryId}/`,
        {
          name,
          parent: parentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "분류가 수정되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};

export const deleteCategory = (categoryId: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .delete(`/api/admin/categories/${categoryId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "분류가 제거되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};

export const addResource = (
  name: string,
  comment: string,
  categoryId?: number
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }
    if (!categoryId) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "분류가 없는 자산의 등록은 허용되지 않습니다." },
      });
      return;
    }

    axios
      .post(
        `/api/admin/resources/`,
        {
          name,
          comment,
          category: categoryId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "자산이 추가되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};

export const editResource = (
  resourceId: number,
  name: string,
  comment: string,
  categoryId?: number
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .patch(
        `/api/admin/resources/${resourceId}/`,
        {
          name,
          comment,
          category: categoryId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "자산이 수정되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};

export const deleteResource = (resourceId: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: CATEGORIES_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
      return;
    }

    axios
      .delete(`/api/admin/resources/${resourceId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function () {
        dispatch({
          type: CATEGORIES_SET_MESSAGES,
          messages: [{ variant: "success", message: "자산이 제거되었습니다." }],
        });
        // @ts-ignore
        dispatch(loadCategories());
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: CATEGORIES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};
