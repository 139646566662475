import React from "react";
import {
  AdminStateType,
  StateType,
  UsersStateType,
  UserType,
} from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import { Checkbox, Spacer } from "../commons";
import { loadUsers } from "../../actions/users";
import {
  Box,
  CustomButton,
  Form,
  Item,
  Left,
  List,
  Right,
  TextField,
} from "./commons";
import styled from "styled-components";
import { resetPassword, updateUser } from "../../actions/admin";
import { Pagination } from "@material-ui/lab";

const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
  `}
`;

const Checkboxes = styled.div`
  ${() => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & > * {
      flex: 0 0 25%;
    }
  `}
`;

const Badge = styled.div`
  padding: 1px 8px;
  font-family: "Roboto", sans-serif;
  border-radius: 4px;
  background-color: #5e9cd7;
  font-size: 0.875rem;
  color: white;
`;

type Props = {
  users: UsersStateType;
  admin: AdminStateType;
  loadUsers: (page?: number, pageSize?: number) => void;
  updateUser: (user: UserType) => void;
  resetPassword: (email: string) => void;
};

function UserManagerView(props: Props) {
  const { users, loadUsers, updateUser, resetPassword } = props;

  const [id, setId] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [nickname, setNickname] = React.useState("");
  const [isStaff, setIsStaff] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    loadUsers();
  }, []);

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    loadUsers(value);
  };

  return (
    <Root>
      <Box>
        <Left>
          <h3>사용자 관리하기</h3>
          <p>가입된 사용자들의 정보를 수정해주세요.</p>
          <Form>
            <Checkboxes>
              <Checkbox
                label="활성화"
                checked={isActive}
                onChange={setIsActive}
              />
              <Checkbox
                label="관리자"
                checked={isStaff}
                onChange={setIsStaff}
              />
            </Checkboxes>
            <TextField
              disabled={id === 0}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="dense"
              size="small"
              variant="outlined"
              label="이메일"
            />
            <TextField
              disabled={id === 0}
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              margin="dense"
              size="small"
              variant="outlined"
              label="닉네임"
            />
          </Form>
          <h4>사용자 목록</h4>
          <List>
            {users.users?.map((user) => (
              <Item
                key={user.id}
                className={clsx(
                  id === user.id && "active",
                  !user.isActive && "disabled"
                )}
                onClick={() => {
                  if (id === user.id) {
                    setId(0);
                    setEmail("");
                    setNickname("");
                    setIsActive(false);
                    setIsStaff(false);
                  } else {
                    setId(user.id);
                    setEmail(user.email);
                    setNickname(user.nickname);
                    setIsActive(user.isActive);
                    setIsStaff(user.isStaff);
                  }
                }}
              >
                <span>{`${user.nickname} (${user.email})`}</span>
                <Spacer />
                {user.isStaff && <Badge>관리자</Badge>}
              </Item>
            ))}
          </List>
          <Pagination
            count={Math.ceil(users.count / 100)}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={handleChange}
          />
        </Left>
        <Right>
          <CustomButton
            onClick={() => {
              updateUser({
                id,
                nickname,
                email,
                isActive,
                isStaff,
              } as any);
            }}
            disabled={id === 0}
            variant="contained"
            color="primary"
            disableElevation
          >
            사용자 수정하기
          </CustomButton>
          <CustomButton
            disabled={id === 0}
            variant="contained"
            disableElevation
            onClick={() => {
              resetPassword(email);
            }}
          >
            임시 비밀번호 발급
          </CustomButton>
        </Right>
      </Box>
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    users: state.users,
    admin: state.admin,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadUsers,
      updateUser,
      resetPassword,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagerView);
