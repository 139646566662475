import axios from "axios";
import {
  GetState,
  Dispatch,
  MessagesType,
  ErrorsType,
} from "../reducers/types";
import { loadCategories } from "./categories";

export const RESOURCES_RESET_MESSAGES = "RESOURCES_RESET_MESSAGES";
export const RESOURCES_RESET_ERRORS = "RESOURCES_RESET_ERRORS";
export const RESOURCES_SET_MESSAGES = "RESOURCES_SET_MESSAGES";
export const RESOURCES_SET_ERRORS = "RESOURCES_SET_ERRORS";
export const RESOURCE_LOAD_SUCCESS = "RESOURCE_LOAD_SUCCESS";
export const RESOURCE_LOAD_FAILURE = "RESOURCE_LOAD_FAILURE";
export const RESOURCE_RESET = "RESOURCE_RESET";

export const resetMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESOURCES_RESET_MESSAGES });
  };
};

export const resetErrors = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESOURCES_RESET_ERRORS });
  };
};

export const setMessages = (messages: MessagesType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESOURCES_SET_MESSAGES, messages });
  };
};

export const setErrors = (errors: ErrorsType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESOURCES_SET_ERRORS, errors });
  };
};

export const loadResource = (resourceId: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: RESOURCE_LOAD_FAILURE });
      return;
    }

    axios
      .get(`/api/ro/resources/${resourceId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: RESOURCE_LOAD_SUCCESS,
          resource: response.data,
        });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: RESOURCE_LOAD_FAILURE,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: RESOURCE_LOAD_FAILURE,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const resetResource = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: RESOURCE_RESET });
  };
};

export const doCommand = (
  resourceId: number,
  command: "반납" | "대여",
  due?: string
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: RESOURCES_SET_ERRORS, detail: "인증되지 않았습니다." });
      return;
    }

    axios
      .post(
        `/api/command/resources/`,
        {
          resource: resourceId,
          command,
          due,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: RESOURCES_SET_MESSAGES,
          messages: [
            {
              variant: "success",
              message: response.data.detail,
            },
          ],
        });
        // @ts-ignore
        dispatch(loadResource(resourceId));
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: RESOURCES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: RESOURCES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadResource(resourceId));
      });
  };
};

export const doAdminCommand = (
  resourceId: number,
  command: "반납" | "사용불가" | "사용허가"
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { user, token } = getState().auth;
    if (!token) {
      dispatch({ type: RESOURCES_SET_ERRORS, detail: "인증되지 않았습니다." });
      return;
    }
    if (!user?.isStaff) {
      dispatch({ type: RESOURCES_SET_ERRORS, detail: "관리자가 아닙니다." });
      return;
    }

    axios
      .post(
        `/api/admin/command/resources/`,
        {
          resource: resourceId,
          command,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: RESOURCES_SET_MESSAGES,
          messages: [
            {
              variant: "success",
              message: response.data.detail,
            },
          ],
        });
        // @ts-ignore
        dispatch(loadCategories());
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: RESOURCES_SET_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: RESOURCES_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
        // @ts-ignore
        dispatch(loadCategories());
      });
  };
};
