import React from "react";
import {
  AuthStateType,
  CategoriesStateType,
  ResourceType,
  StateType,
} from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { loadCategories } from "../../actions/categories";
import { Paper as MuiPaper } from "@material-ui/core";
import { CategoryTree, ResourceDialog } from "./commons";

const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
  `}
`;

const Paper = styled(MuiPaper)`
  padding: 16px;
  & > div > div {
    margin: 0 !important;
  }
`;

type Props = {
  auth: AuthStateType;
  categories: CategoriesStateType;
  loadCategories: () => void;
};

function ResourcesDashboardView(props: Props) {
  const { auth, categories: categoriesState, loadCategories } = props;
  const { categories } = categoriesState;
  const [resource, setResource] = React.useState<ResourceType | null>(null);
  const [resourceOpen, setResourceOpen] = React.useState(false);

  React.useEffect(() => {
    if (auth.status === "authenticated") {
      loadCategories();
    }
  }, [auth.status]);

  const onAction = React.useCallback((aType, aTarget, target) => {
    if (aType === "show" && aTarget === "resource") {
      setResourceOpen(true);
      setResource(target as any);
    }
  }, []);

  return (
    <Root>
      <Paper variant="outlined">
        <CategoryTree categories={categories} level={0} onAction={onAction} />

        <ResourceDialog
          open={resourceOpen}
          onClose={() => setResourceOpen(false)}
          onDoCommand={() => {}}
          resource={resource}
          auth={auth}
          onlyRecord
          small
        />
      </Paper>
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
    categories: state.categories,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadCategories,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesDashboardView);
