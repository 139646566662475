import axios from "axios";
import {
  GetState,
  Dispatch,
  MessagesType,
  ErrorsType,
  ExcelType,
} from "../reducers/types";

export const EXCEL_MESSAGES = "EXCEL_MESSAGES";
export const EXCEL_ERRORS = "EXCEL_ERRORS";
export const EXCEL_SET = "EXCEL_SET";

export const resetMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: EXCEL_MESSAGES, messages: [] });
  };
};

export const resetErrors = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: EXCEL_ERRORS, errors: null });
  };
};

export const setMessages = (messages: MessagesType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: EXCEL_MESSAGES, messages });
  };
};

export const setErrors = (errors: ErrorsType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: EXCEL_ERRORS, errors });
  };
};

export const setExcel = (excel: ExcelType | null) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: EXCEL_SET, excel });
  };
};

export const createExcel = (period: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: EXCEL_ERRORS,
        errors: {
          detail: "인증되지 않았습니다.",
        },
      });
      return;
    }

    axios
      .post(
        `/api/excels/`,
        { period },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: EXCEL_SET,
          excel: response.data,
        });
        dispatch({
          type: EXCEL_MESSAGES,
          messages: [
            {
              variant: "success",
              message: "엑셀 파일을 생성중입니다. 곧 다운로드가 시작됩니다.",
            },
          ],
        });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: EXCEL_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: EXCEL_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const downloadExcel = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: EXCEL_ERRORS,
        errors: {
          detail: "인증되지 않았습니다.",
        },
      });
      return;
    }

    const { excel } = getState().excel;
    if (!excel) {
      return;
    }

    axios
      .get(`/api/excels/${excel.id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        if (
          response.data.status === "failed" ||
          response.data.status === "deleted"
        ) {
          dispatch({
            type: EXCEL_SET,
          });
          dispatch({
            type: EXCEL_ERRORS,
            errors: {
              detail: "일시적인 장애로 인해 엑셀 파일 생성에 실패하였습니다.",
            },
          });
        } else if (response.data.status === "complete") {
          const a = document.createElement("a");
          const url = response.data.file;
          const tokens = url.split("/");
          const filename = tokens[tokens.length - 1];
          a.href = url;
          a.download = decodeURI(filename);
          a.dispatchEvent(new MouseEvent("click"));

          dispatch({
            type: EXCEL_SET,
          });
        } else {
          dispatch({
            type: EXCEL_SET,
            excel: response.data,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: EXCEL_ERRORS,
            errors: error.response.data,
          });
        } else {
          dispatch({
            type: EXCEL_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};
