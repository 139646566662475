import React from "react";
import styled from "styled-components";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  TextField as MuiTextField,
} from "@material-ui/core";
import {
  AuthStateType,
  CategoryType,
  RecordType,
  ResourceType,
} from "../../reducers/types";
import { Spacer } from "../commons";
import QRCode from "qrcode";
import Hashids from "hashids";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import { getStatus } from "../../utils";

export const Box = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(1, 2)};
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: "Roboto", sans-serif;
  `}
`;

export const Left = styled.div`
  ${({ theme }) => `
    flex: 1 1 66%;
    padding: ${theme.spacing(4)}px;
    & > h3 {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
    & > p {
      font-size: 1rem;
      line-height: 1.25rem;
      margin: ${theme.spacing(1, 0, 3)};
    }
    & > h4 {
      font-size: 1rem;
      line-height: 1.25rem;
      margin: ${theme.spacing(3, 0, 1)};
    }
  `}
`;

export const Right = styled.div`
  ${({ theme }) => `
    flex: 1 1 34%;
    padding: ${theme.spacing(2)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #eaeaea;
    background-color: ${"#eeeeee"};
  `}
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextField = styled(MuiTextField)`
  & input {
    font-family: "Roboto", sans-serif;
  }
`;

export const CustomButton = styled(Button)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
    min-width: 160px;
    width: 70%;
    font-size: 1.125rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  `}
`;

export const List = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(1, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content; center;
  `}
`;

export const Item = styled.div`
  ${({ theme }) => `
    margin: 4px 0;
    padding: ${theme.spacing(1, 2)};
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.15);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    color: #000000;
    transition: color 300ms, background-color 300ms;
    font-size: 1rem;
    &.active {
      background-color: ${theme.palette.primary.main};
      color: #ffffff;
    }
    &.disabled {
      opacity: 0.6;
      & > span {
        text-decoration-line: line-through;
      }
    }
  `}
`;

export type ActionTarget = "category" | "resource";
export type ActionType = "add" | "edit" | "delete" | "show";

const ResourceRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4px;
  & > .MuiIcon-root {
    margin-left: 2px;
    cursor: pointer;
    font-size: 1.25rem;
    opacity: 0.4;
    z-index: 2;
  }
  & > .MuiIcon-root:nth-last-child(1) {
    color: #e74c3c;
  }
  & > .MuiIcon-root:nth-last-child(2) {
    margin-left: 28px;
    color: #3498db;
  }
`;

const ResourceWrapper = styled.div`
  flex: 1 1 auto;
  margin: 2px 0;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  border: 1px solid rgba(45, 45, 45, 0.15);
  border-radius: 4px;
  & > span:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  & > span:not(:nth-child(2)):not(.MuiIcon-root) {
    padding: 2px 8px;
    font-size: 0.875rem;
    color: #2d2d2d;
    border: 1px solid #2d2d2d;
    border-radius: 2px;
    margin-left: 8px;
  }
  & > .MuiIcon-root {
    margin-left: 2px;
    cursor: pointer;
    font-size: 1.25rem;
    opacity: 0.4;
  }
  & > .MuiIcon-root:first-child {
    margin: 0 8px 0 0;
    cursor: default;
  }
  @media screen and (max-width: 600px) {
    & > span:not(:nth-child(2)):not(.MuiIcon-root) {
      display: none;
    }
  }
`;

type ResourceProps = {
  editable?: boolean;
  onAction?: (
    actionType: ActionType,
    actionTarget: ActionTarget,
    target: ResourceType
  ) => void;
  resource: ResourceType;
};

function Resource(props: ResourceProps) {
  const { editable, onAction, resource } = props;

  return (
    <ResourceRoot>
      <ResourceWrapper>
        <Icon>devices</Icon>
        <span
          onClick={() => {
            if (onAction) {
              onAction("show", "resource", resource);
            }
          }}
        >
          {resource.name}
        </span>
        <Spacer />
        {resource.user && <span>대여자: {resource.user?.nickname}</span>}
        <span
          style={{
            color: resource.isAvailable ? "#27ae60" : "#e74c3c",
            borderColor: resource.isAvailable ? "#27ae60" : "#e74c3c",
          }}
        >
          {getStatus(resource)}
        </span>
      </ResourceWrapper>

      {editable && (
        <>
          <Icon
            onClick={() => {
              if (onAction) {
                onAction("edit", "resource", resource);
              }
            }}
          >
            adjust
          </Icon>
          <Icon
            onClick={() => {
              if (onAction) {
                onAction("delete", "resource", resource);
              }
            }}
          >
            highlight_off
          </Icon>
        </>
      )}
    </ResourceRoot>
  );
}

const CategoryRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  & > *:not(:first-child) {
    margin-left: 32px;
  }
`;

const CategoryWrapper = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  & > span:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > .MuiIcon-root {
    margin-left: 2px;
    cursor: pointer;
    font-size: 1.25rem;
    opacity: 0.4;
  }
  & > .MuiIcon-root:first-child {
    margin: 0 8px 0 0;
    font-size: 1.5rem;
    opacity: 1;
  }
  & > .MuiIcon-root:nth-last-child(1) {
    color: #e74c3c;
  }
  & > .MuiIcon-root:nth-last-child(2) {
    color: #3498db;
  }
  & > .MuiIcon-root:nth-last-child(3):not(:first-child) {
    margin-left: 8px;
    color: #2ecc71;
  }
`;

type CategoryProps = {
  editable?: boolean;
  onAction?: (
    actionType: ActionType,
    actionTarget: ActionTarget,
    target: CategoryType | ResourceType
  ) => void;
  category: CategoryType;
  onOpenChange: (open: boolean) => void;
};

function Category(props: CategoryProps) {
  const { editable, onAction, category, onOpenChange } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <CategoryRoot>
      <CategoryWrapper>
        <Icon
          onClick={() => {
            onOpenChange(!open);
            setOpen((prev) => !prev);
          }}
        >
          {open ? "expand_more" : "chevron_right"}
        </Icon>
        <span>{category.name}</span>
        <Spacer />
        {editable && (
          <>
            <Icon
              onClick={() => {
                if (onAction) {
                  onAction("add", "resource", category);
                }
              }}
            >
              add_circle_outline
            </Icon>
            <Icon
              onClick={() => {
                if (onAction) {
                  onAction("edit", "category", category);
                }
              }}
            >
              adjust
            </Icon>
            <Icon
              onClick={() => {
                if (onAction) {
                  onAction("delete", "category", category);
                }
              }}
            >
              highlight_off
            </Icon>
          </>
        )}
      </CategoryWrapper>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {category.resources.map((resource) => (
          <Resource
            editable={editable}
            onAction={onAction}
            key={`resource-${resource.id}`}
            resource={resource}
          />
        ))}
      </Collapse>
    </CategoryRoot>
  );
}

const CategoryTreeRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  & > div {
    margin-left: 24px;
  }
`;

type CategoryTreeProps = {
  editable?: boolean;
  onAction?: (
    actionType: ActionType,
    actionTarget: ActionTarget,
    target: CategoryType | ResourceType
  ) => void;
  categories: CategoryType[];
  level: number;
};

export function CategoryTree(props: CategoryTreeProps) {
  const { editable, onAction, categories, level } = props;
  const [collapses, setCollapses] = React.useState<boolean[]>([]);

  return (
    <CategoryTreeRoot>
      {categories.map((category, i) => (
        <div key={`category-${category.id}`}>
          <Category
            editable={editable}
            onAction={onAction}
            category={category}
            onOpenChange={(o) => {
              const newCollapses = collapses.slice();
              newCollapses[i] = !o;
              setCollapses(newCollapses);
            }}
          />
          <Collapse in={!collapses[i]} timeout="auto">
            <CategoryTree
              editable={editable}
              onAction={onAction}
              categories={category.children}
              level={level + 1}
            />
          </Collapse>
        </div>
      ))}
    </CategoryTreeRoot>
  );
}

const ResourceDialogContentWrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > img {
    cursor: pointer;
    width: 250px;
    height: 250px;
  }
  & > h4 {
    color: ${theme.palette.primary.main};
    font-size: 0.875rem;
  }
  & > h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
  & > div:nth-last-child(2) {
    margin-top: 16px;
  }
  `}
`;

const Buttons = styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > button {
    margin: 4px;
  }
  @media screen and (max-width: 340px) {
    width: calc(100% - 40px);
    min-width: 200px;
  }
`;

const MaterialTableWrapper = styled.div`
  margin-top: 16px;
  & > .MuiPaper-root {
    box-shadow: none;
    & > .MuiToolbar-root {
      padding: 0 8px;
      & > .MuiTextField-root {
        display: none;
      }
    }
  }
`;

const Info = styled.div`
  margin-top: 24px;
  text-align: center;
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 400;
    color: #3498db;
  }
`;

type ResourceDialogProps = {
  open: boolean;
  onClose: () => void;
  resource: ResourceType | null;
  onDoCommand: (
    resourceId: number,
    command: "반납" | "사용불가" | "사용허가"
  ) => void;
  auth: AuthStateType;
  onlyRecord?: boolean;
  small?: boolean;
};

export function ResourceDialog(props: ResourceDialogProps) {
  const {
    open,
    onClose,
    resource,
    onDoCommand,
    auth,
    onlyRecord,
    small,
  } = props;
  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    if (resource && open) {
      const hashids = new Hashids("", 16);
      const id = hashids.encode(resource.id);
      QRCode.toDataURL(`${window.location.origin}/resources/${id}`, function (
        _,
        url
      ) {
        setUrl(url);
      });
    }
  }, [open, resource]);

  const moveToResourcePage = React.useCallback(() => {
    if (resource) {
      const hashids = new Hashids("", 16);
      window.open(`/resources/${hashids.encode(resource.id)}`, "_blank");
    }
  }, [resource]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        {!onlyRecord && (
          <ResourceDialogContentWrapper>
            <img src={url} alt="qrcode" onClick={moveToResourcePage} />
            <h4>{resource?.categoryName}</h4>
            <h3>{resource?.name}</h3>
            <p>
              <strong>상태 :</strong>{" "}
              <span
                style={{
                  color: resource?.isAvailable ? "#27ae60" : "#e74c3c",
                }}
              >
                {getStatus(resource)}
              </span>
            </p>
            <p>
              <strong>대여자 :</strong>{" "}
              {resource?.user ? resource.user.nickname : "없음"}
            </p>
            <p>
              <strong>시료 정보:</strong> {resource?.comment}
            </p>
            {resource !== null && resource.due && (
              <p>
                <strong>반납 예정일:</strong>{" "}
                {moment(resource.due).format("YYYY.MM.DD")}
              </p>
            )}
            <Buttons>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => {
                  if (resource) {
                    onDoCommand(resource.id, "반납");
                  }
                }}
              >
                반납
              </Button>
            </Buttons>
            <Buttons>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                fullWidth
                onClick={() => {
                  if (resource) {
                    onDoCommand(resource.id, "사용불가");
                  }
                }}
              >
                사용불가
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                onClick={() => {
                  if (resource) {
                    onDoCommand(resource.id, "사용허가");
                  }
                }}
              >
                사용허가
              </Button>
            </Buttons>
          </ResourceDialogContentWrapper>
        )}

        {onlyRecord && (
          <Info>
            <h4>{resource?.categoryName}</h4>
            <h3>{resource?.name}</h3>
            {resource !== null && (
              <>
                <p>
                  <strong>상태 :</strong>{" "}
                  <span
                    style={{
                      color: resource?.isAvailable ? "#27ae60" : "#e74c3c",
                    }}
                  >
                    {getStatus(resource)}
                  </span>
                </p>
                <p>
                  <strong>대여자 :</strong>{" "}
                  {resource?.user ? resource.user.nickname : "없음"}
                </p>
                <p>
                  <strong>시료 정보:</strong> {resource?.comment}
                </p>
                {resource.due && (
                  <p>
                    <strong>반납 예정일:</strong>{" "}
                    {moment(resource.due).format("YYYY.MM.DD")}
                  </p>
                )}
              </>
            )}
          </Info>
        )}

        <MaterialTableWrapper>
          <MaterialTable
            columns={[
              { title: "실행자", field: "executor" },
              { title: "상태", field: "actionDisplay" },
              { title: "수정 날짜", field: "created" },
              { title: "기존 이용자", field: "prev" },
              { title: "변경 이용자", field: "next" },
            ]}
            title={`과거이력`}
            options={{
              pageSize: small ? 5 : 10,
              pageSizeOptions: small ? [5] : [10, 25, 50, 100],
              padding: "dense",
            }}
            data={(query) =>
              new Promise((resolve, reject) => {
                axios
                  .get(
                    `/api/admin/records/?page=${query.page + 1}&page_size=${
                      query.pageSize
                    }&search=${query.search}&resource=${resource?.id}`,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${auth.token}`,
                      },
                    }
                  )
                  .then(function (response) {
                    const data = response.data.results.map(
                      (record: RecordType) => {
                        return {
                          ...record,
                          category: record.resource.categoryName,
                          name: record.resource.name,
                          executor: record.executor.nickname,
                          created: moment(record.created).format("YY-MM-DD"),
                          prev: record.prev?.nickname,
                          next: record.next?.nickname,
                        };
                      }
                    );
                    resolve({
                      data,
                      page: query.page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch(function () {
                    reject();
                  });
              })
            }
          />
        </MaterialTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
