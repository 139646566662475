import axios from "axios";
import {
  GetState,
  Dispatch,
  MessagesType,
  ErrorsType,
} from "../reducers/types";

export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_LOADED = "AUTH_LOADED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_REGISTERED = "AUTH_REGISTERED";
export const AUTH_FROM = "AUTH_FROM";
export const AUTH_RESET_MESSAGES = "AUTH_RESET_MESSAGES";
export const AUTH_RESET_ERRORS = "AUTH_RESET_ERRORS";
export const AUTH_SET_MESSAGES = "AUTH_SET_MESSAGES";
export const AUTH_SET_ERRORS = "AUTH_SET_ERRORS";

export const resetMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_RESET_MESSAGES });
  };
};

export const resetErrors = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_RESET_ERRORS });
  };
};

export const setMessages = (messages: MessagesType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_SET_MESSAGES, messages });
  };
};

export const setErrors = (errors: ErrorsType) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_SET_ERRORS, errors });
  };
};

export const loadUser = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: AUTH_LOADING });

    const { token } = getState().auth;
    if (!token) {
      dispatch({ type: AUTH_FAILED });
      return;
    }

    axios
      .get(`/api/auth/user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({ type: AUTH_LOADED, user: response.data });
        return null;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: AUTH_FAILED, errors: error.response.data });
        } else {
          dispatch({
            type: AUTH_FAILED,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const login = (email: string, password: string) => {
  return (dispatch: Dispatch) => {
    axios
      .post(
        `/api/auth/login/`,
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        dispatch({
          type: AUTH_LOADED,
          token: response.data.token,
          user: response.data.user,
        });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: AUTH_FAILED, errors: error.response.data });
        } else {
          dispatch({
            type: AUTH_FAILED,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const logout = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { token } = getState().auth;

    axios
      .post(
        `/api/auth/logout/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(function () {
        dispatch({ type: AUTH_LOGOUT });
        return;
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: AUTH_FAILED, errors: error.response.data });
        } else {
          dispatch({
            type: AUTH_FAILED,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const register = (email: string, nickname: string, password: string) => {
  return (dispatch: Dispatch) => {
    axios
      .post(
        `/api/auth/register/`,
        { email, nickname, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function () {
        dispatch({
          type: AUTH_REGISTERED,
          messages: [
            {
              variant: "success",
              message:
                "회원 가입이 완료되었습니다. 이메일 인증을 진행해주세요.",
            },
          ],
        });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: AUTH_FAILED, errors: error.response.data });
        } else {
          dispatch({
            type: AUTH_FAILED,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const updateUser = (
  nickname: string,
  isEmailNotificationEnable: boolean,
  oldPassword?: string,
  newPassword?: string
) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const { user, token } = getState().auth;
    if (!user) {
      dispatch({
        type: AUTH_SET_ERRORS,
        errors: { detail: "인증되지 않았습니다." },
      });
    }

    const data = new FormData();
    data.append("nickname", nickname);
    data.append(
      "isEmailNotificationEnable",
      isEmailNotificationEnable.toString()
    );
    if (oldPassword) {
      data.append("oldPassword", oldPassword);
    }
    if (newPassword) {
      data.append("newPassword", newPassword);
    }

    axios
      .post(`/api/auth/update-user/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: AUTH_SET_MESSAGES,
          messages: [
            {
              variant: "success",
              message: response.data.detail,
            },
          ],
        });
        //@ts-ignore
        dispatch(loadUser());
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: AUTH_SET_ERRORS, errors: error.response.data });
        } else {
          dispatch({
            type: AUTH_SET_ERRORS,
            errors: { detail: "서버 에러" },
          });
        }
      });
  };
};

export const setFrom = (from: string | null) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_FROM, from });
  };
};

export const resetFrom = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: AUTH_FROM, from: null });
  };
};
