import React from "react";
import { Provider } from "react-redux";
import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { hot } from "react-hot-loader";
import { SnackbarProvider } from "notistack";
import { Store } from "./reducers/types";
import Routes from "./Routes";
import { useWindowSize } from "./utils";
import { BrowserRouter } from "react-router-dom";
import Messages from "./components/Messages";
import Errors from "./components/Errors";
import ExcelDownloadChecker from "./components/ExcelDownloadChecker";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5E9CD7",
      light: "#7eafdf",
      dark: "#416d96",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#b63b44",
      light: "#c46269",
      dark: "#7f292f",
      contrastText: "#ffffff",
    },
  },
});

function App({ store }: { store: Store }) {
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [width, height]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="ko">
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                preventDuplicate
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
              >
                <Errors />
                <Messages />
                <ExcelDownloadChecker />
                <Routes />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default hot(module)(App);
