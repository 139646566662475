import React from "react";
import { ResourceType } from "./reducers/types";

export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useInterval(callback: any, delay: number | null) {
  const savedCallback = React.useRef<any>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // @ts-ignore
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function secsToMinSecs(secs: number) {
  if (!secs) {
    return "00:00";
  }
  const mins = Math.floor(secs / 60);
  const remains = secs % 60;
  return `${mins.toString().padStart(2, "0")}:${remains
    .toString()
    .padStart(2, "0")}`;
}

export function validateEmail(email: string) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

export function getStatus(resource: ResourceType | null) {
  if (!resource) {
    return "대여불가";
  } else if (resource.isAvailable && !resource.user) {
    return "대여가능";
  } else if (resource.isAvailable && resource.user) {
    return "대여중";
  } else {
    return "대여불가";
  }
}
