import { combineReducers } from "redux";
import auth from "./auth";
import admin from "./admin";
import users from "./users";
import categories from "./categories";
import records from "./records";
import resources from "./resources";
import excel from "./excel";

export default function createRootReducer() {
  return combineReducers({
    auth,
    admin,
    users,
    categories,
    records,
    resources,
    excel,
  });
}
