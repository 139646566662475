import React from "react";
import {
  AuthStateType,
  CategoriesStateType,
  CategoryType,
  ResourceType,
  StateType,
} from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Paper as MuiPaper,
  WithStyles,
  Theme,
  createStyles,
  withStyles,
  Typography,
  IconButton,
  Icon,
  TextField,
  Fab,
} from "@material-ui/core";
import {
  ActionTarget,
  ActionType,
  CategoryTree,
  ResourceDialog,
} from "./commons";
import {
  addCategory,
  addResource,
  deleteCategory,
  deleteResource,
  editCategory,
  editResource,
  loadCategories,
  loadCategory,
  resetCategory,
} from "../../actions/categories";
import { ConfirmDialog, Spacer } from "../commons";
import { doAdminCommand } from "../../actions/resources";
import Excel from "../../assets/images/excel.png";
import { createExcel } from "../../actions/excel";

const Root = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2, 0)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 2;
    & .MuiPaper-root {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
    & .MuiTablePagination-root {
      border-bottom: none;
    }
  `}
`;

const Paper = styled(MuiPaper)`
  padding: 16px;
  & > div > div {
    margin: 0 !important;
  }
  & > .MuiIcon-root {
    cursor: pointer;
    margin: 0 6px;
    font-size: 1.25rem;
    color: #2ecc71;
    opacity: 0.6;
  }
`;

const Action = styled.div`
  margin-top: 16px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & > .MuiFormControl-root {
    background-color: white;
    margin-right: 8px;
  }
  & > .MuiFab-root {
    background-color: white;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    & img {
      width: 24px;
      height: 24px;
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.7);
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    closeButton: {
      color: theme.palette.grey[500],
      padding: 0,
    },
    targetButton: {
      marginLeft: theme.spacing(2),
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  actionTarget: ActionTarget;
  actionType: ActionType;
  onActionTargetChange: (actionTarget: ActionTarget) => void;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    classes,
    actionTarget,
    actionType,
    onActionTargetChange,
    onClose,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        style={{
          color: actionTarget === "category" ? "#d35400" : "#2980b9",
        }}
      >
        {`${actionTarget === "category" ? "분류" : "자산"} ${
          actionType === "add" ? "추가하기" : "수정하기"
        }`}
      </Typography>
      {actionType === "add" && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.targetButton}
          disableElevation
          onClick={() => {
            onActionTargetChange(
              actionTarget === "category" ? "resource" : "category"
            );
          }}
          style={{
            backgroundColor:
              actionTarget !== "category" ? "#d35400" : "#2980b9",
          }}
        >
          {`${actionTarget === "category" ? "자산" : "분류"} 추가하기로 변경`}
        </Button>
      )}
      <Spacer />
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type Props = {
  auth: AuthStateType;
  categories: CategoriesStateType;
  loadCategories: () => void;
  loadCategory: (categoryId: number) => void;
  resetCategory: () => void;
  addCategory: (name: string, parentId?: number) => void;
  editCategory: (categoryId: number, name: string, parentId?: number) => void;
  deleteCategory: (categoryId: number) => void;
  addResource: (name: string, comment: string, categoryId?: number) => void;
  editResource: (
    resourceId: number,
    name: string,
    comment: string,
    categoryId?: number
  ) => void;
  deleteResource: (resourceId: number) => void;
  doAdminCommand: (
    resourceId: number,
    command: "반납" | "사용불가" | "사용허가"
  ) => void;
  createExcel: (period: string) => void;
};

function ResourcesManagerView(props: Props) {
  const {
    auth,
    categories: categoriesState,
    loadCategories,
    loadCategory,
    resetCategory,
    addCategory,
    editCategory,
    deleteCategory,
    addResource,
    editResource,
    deleteResource,
    doAdminCommand,
    createExcel,
  } = props;
  const { category: parent, categories } = categoriesState;
  const [open, setOpen] = React.useState(false);
  const [actionTarget, setActionTarget] = React.useState<ActionTarget>(
    "resource"
  );
  const [actionType, setActionType] = React.useState<ActionType>("add");
  const [category, setCategory] = React.useState<CategoryType | null>(null);
  const [resource, setResource] = React.useState<ResourceType | null>(null);

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [messages, setMessages] = React.useState<string[]>([]);

  const [parentName, setParentName] = React.useState("");
  const [categoryName, setCategoryName] = React.useState("");
  const [resourceName, setResourceName] = React.useState("");
  const [resourceComment, setResourceComment] = React.useState("");

  const [resourceOpen, setResourceOpen] = React.useState(false);

  React.useEffect(() => {
    if (auth.status === "authenticated") {
      loadCategories();
    }
  }, [auth.status]);

  React.useEffect(() => {
    setParentName(parent ? parent.fullname : "");
  }, [parent]);

  const handleCancel = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleClose = React.useCallback(() => {
    if (actionType === "add" && actionTarget === "category") {
      addCategory(categoryName, category?.id);
    }
    if (actionType === "edit" && actionTarget === "category") {
      if (category) {
        editCategory(category.id, categoryName, parent?.id);
      }
    }
    if (actionType === "add" && actionTarget === "resource") {
      addResource(resourceName, resourceComment, category?.id);
    }
    if (actionType === "edit" && actionTarget === "resource") {
      if (resource) {
        editResource(
          resource.id,
          resourceName,
          resourceComment,
          resource.category
        );
      }
    }
    setOpen(false);
  }, [
    actionType,
    actionTarget,
    category,
    resource,
    categoryName,
    resourceName,
    resourceComment,
  ]);

  const handleDeleteCancel = React.useCallback(() => {
    setDeleteOpen(false);
  }, []);

  const handleDeleteClose = React.useCallback(() => {
    if (actionTarget === "category" && category) {
      deleteCategory(category.id);
    }
    if (actionTarget === "resource" && resource) {
      deleteResource(resource.id);
    }
    setDeleteOpen(false);
  }, [actionTarget, category, resource]);

  const onAction = React.useCallback((aType, aTarget, target) => {
    setParentName("");
    setActionType(aType);
    setActionTarget(aTarget);
    if (aType === "add" && aTarget === "category") {
      setOpen(true);
      setCategory(target as CategoryType);
      setCategoryName("");
      setResourceName("");
      setResourceComment("");
      if (target && target.parent) {
        loadCategory((target as CategoryType).parent);
      } else {
        resetCategory();
      }
    }
    if (aType === "add" && aTarget === "resource") {
      setOpen(true);
      setCategory(target as CategoryType);
      setCategoryName("");
      setResourceName("");
      setResourceComment("");
      if (target) {
        loadCategory((target as CategoryType).id);
      } else {
        resetCategory();
      }
    }
    if (aType === "edit" && aTarget === "category") {
      setOpen(true);
      setCategory(target as CategoryType);
      setCategoryName((target as CategoryType).name);
      if (target && target.parent) {
        loadCategory((target as CategoryType).parent);
      } else {
        resetCategory();
      }
    }
    if (aType === "edit" && aTarget === "resource") {
      setOpen(true);
      setResource(target as ResourceType);
      setResourceName((target as ResourceType).name);
      setResourceComment((target as ResourceType).comment);
      if (target) {
        loadCategory((target as ResourceType).category);
      } else {
        resetCategory();
      }
    }
    if (aType === "delete" && aTarget === "category") {
      setDeleteOpen(true);
      setCategory(target as any);
      setMessages([
        `정말 분류 "${target.name}" 을(를)`,
        `삭제하시겠습니까?`,
        `하위의 모든 자산와 분류들도`,
        `함께 삭제됩니다.`,
      ]);
    }
    if (aType === "delete" && aTarget === "resource") {
      setDeleteOpen(true);
      setResource(target as any);
      setMessages([`정말 자산 "${target.name}" 을(를)`, `삭제하시겠습니까?`]);
    }
    if (aType === "show" && aTarget === "resource") {
      setResourceOpen(true);
      setResource(target as any);
    }
  }, []);

  return (
    <Root>
      <Paper variant="outlined">
        <Icon
          onClick={() => {
            onAction("add", "category", null);
          }}
        >
          add_circle_outline
        </Icon>

        <CategoryTree
          editable
          onAction={onAction}
          categories={categories}
          level={0}
        />
      </Paper>

      <Action>
        <Fab
          aria-label="download"
          onClick={() => {
            createExcel("now");
          }}
        >
          <img src={Excel} alt="excel" />
        </Fab>
      </Action>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          actionTarget={actionTarget}
          actionType={actionType}
          onActionTargetChange={(aTarget) => {
            setCategoryName("");
            setResourceName("");
            setResourceComment("");
            setActionTarget(aTarget);
          }}
          onClose={handleCancel}
        />
        <DialogContent>
          {actionTarget === "category" && actionType === "add" && (
            <>
              <TextField
                disabled
                fullWidth
                label="상위분류"
                variant="outlined"
                margin="dense"
                value={parentName || "상위분류 없음"}
              />
              <TextField
                fullWidth
                label="분류명"
                variant="outlined"
                margin="dense"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </>
          )}
          {actionTarget === "category" && actionType === "edit" && (
            <>
              <TextField
                disabled
                fullWidth
                label="상위분류"
                variant="outlined"
                margin="dense"
                value={parentName || "상위분류 없음"}
              />
              <TextField
                fullWidth
                label="분류명"
                variant="outlined"
                margin="dense"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </>
          )}
          {actionTarget === "resource" && actionType === "add" && (
            <>
              <TextField
                disabled
                fullWidth
                label="상위분류"
                variant="outlined"
                margin="dense"
                value={parentName}
              />
              <TextField
                fullWidth
                label="자산명"
                variant="outlined"
                margin="dense"
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value)}
              />
              <TextField
                fullWidth
                label="시료 정보"
                variant="outlined"
                margin="dense"
                value={resourceComment}
                onChange={(e) => setResourceComment(e.target.value)}
              />
            </>
          )}
          {actionTarget === "resource" && actionType === "edit" && (
            <>
              <TextField
                disabled
                fullWidth
                label="상위분류"
                variant="outlined"
                margin="dense"
                value={parentName}
              />
              <TextField
                fullWidth
                label="자산명"
                variant="outlined"
                margin="dense"
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value)}
              />
              <TextField
                fullWidth
                label="시료 정보"
                variant="outlined"
                margin="dense"
                value={resourceComment}
                onChange={(e) => setResourceComment(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            취소
          </Button>
          <Button onClick={handleClose} color="primary">
            {actionType === "add" ? "추가" : "수정"}
          </Button>
        </DialogActions>
      </Dialog>

      <ResourceDialog
        open={resourceOpen}
        onClose={() => setResourceOpen(false)}
        onDoCommand={(resourceId, command) => {
          doAdminCommand(resourceId, command);
        }}
        resource={resource}
        auth={auth}
        small
      />

      <ConfirmDialog
        open={deleteOpen}
        messages={messages}
        onClose={handleDeleteClose}
        onCancel={handleDeleteCancel}
      />
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
    categories: state.categories,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadCategories,
      loadCategory,
      resetCategory,
      addCategory,
      editCategory,
      deleteCategory,
      addResource,
      editResource,
      deleteResource,
      doAdminCommand,
      createExcel,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesManagerView);
