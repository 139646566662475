import { RecordsActionType, RecordsStateType } from "./types";
import {
  RECORDS_LOAD_FAILURE,
  RECORDS_LOAD_SUCCESS,
  RECORDS_RESET,
  RECORDS_RESET_ERRORS,
  RECORDS_RESET_MESSAGES,
  RECORDS_SET_ERRORS,
  RECORDS_SET_MESSAGES,
} from "../actions/records";

const initialState: RecordsStateType = {
  records: [],
  messages: [],
  errors: null,
};

export default function records(
  state = initialState,
  action: RecordsActionType
) {
  switch (action.type) {
    case RECORDS_LOAD_SUCCESS:
      return {
        ...state,
        records: action.records,
      };

    case RECORDS_RESET_MESSAGES:
      return {
        ...state,
        messages: [],
      };

    case RECORDS_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    case RECORDS_SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case RECORDS_LOAD_FAILURE:
    case RECORDS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    case RECORDS_RESET:
      return {
        ...state,
        records: [],
      };

    default:
      return state;
  }
}
