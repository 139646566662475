import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import styled from "styled-components";
import jsQR from "jsqr";

const Message = styled.p`
  font-weight: 600;
  padding: 8px 16px;
  margin: 0;
  border-radius: 4px;
  background-color: #eeeeee;
  margin-bottom: 8px;
`;

const Canvas = styled.canvas`
  width: 100%;
  border-radius: 4px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function ScannerDialog(props: Props) {
  const { open, onClose } = props;
  const [status, setStatus] = React.useState("준비중입니다...");
  const [url, setUrl] = React.useState("");
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const streamRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!open) {
      videoRef.current?.remove();
      streamRef.current
        ?.getTracks()
        .forEach(function (track: MediaStreamTrack) {
          track.stop();
        });
    }
  }, [open]);

  const ref = React.useCallback(
    (node) => {
      if (!node || !open) {
        return;
      }

      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        // @ts-ignore
        navigator.mediaDevices = {};
      }

      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia =
            // @ts-ignore
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      const video = document.createElement("video");
      const canvasElement = node;
      const canvas = canvasElement.getContext("2d") as CanvasRenderingContext2D;

      function drawLine(begin: any, end: any, color: any) {
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
      }

      // Use facingMode: environment to attemt to get the front camera on phones
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then(function (stream) {
          video.srcObject = stream;
          streamRef.current = stream;
          video.setAttribute("playsinline", ""); // required to tell iOS safari we don't want fullscreen
          video.play();
          setStatus("QR 코드를 스캔하는 중입니다...");
          requestAnimationFrame(tick);
        })
        .catch(function () {
          setStatus("카메라가 없거나 스캔 기능을 사용할 수 없는 환경입니다.");
        });

      function tick() {
        if (open && video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.hidden = false;

          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(
            video,
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          const imageData = canvas.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          if (code) {
            drawLine(
              code.location.topLeftCorner,
              code.location.topRightCorner,
              "#FF3B58"
            );
            drawLine(
              code.location.topRightCorner,
              code.location.bottomRightCorner,
              "#FF3B58"
            );
            drawLine(
              code.location.bottomRightCorner,
              code.location.bottomLeftCorner,
              "#FF3B58"
            );
            drawLine(
              code.location.bottomLeftCorner,
              code.location.topLeftCorner,
              "#FF3B58"
            );

            if (code.data.startsWith(window.location.origin)) {
              if (url !== code.data) {
                setUrl(code.data);
              }
              setStatus("QR 코드가 인식되었습니다.");
            } else {
              setStatus("인식할 수 없는 QR 코드 입니다.");
            }
          } else {
            setStatus("QR 코드를 스캔하는 중입니다...");
          }
        }
        if (open) {
          requestAnimationFrame(tick);
        }
      }
    },
    [open]
  );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogContent>
        <Message>{status}</Message>
        <Message>인식된 자산: {url ? url : "없음"}</Message>
        <Canvas ref={ref} id="preview" />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={url === ""}
          style={{ color: "#5E9CD7", opacity: url === "" ? 0.3 : 1 }}
          disableElevation
          onClick={() => {
            document.location.href = url;
          }}
        >
          자산으로 이동
        </Button>
        <Button disableElevation onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
