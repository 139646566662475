import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { AuthStateType, StateType } from "../../reducers/types";
import { bindActionCreators, Dispatch } from "redux";
import { logout, updateUser } from "../../actions/auth";
import { connect } from "react-redux";
import { Spacer } from "../commons";
import {
  createStyles,
  Hidden,
  Icon,
  Tab,
  Tabs,
  Theme,
  withStyles,
} from "@material-ui/core";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import SwipeableViews from "react-swipeable-views";
import ResourcesDashboardView from "./ResourcesDashboardView";
import UserManagerView from "./UserManagerView";
import MyRecordsView from "./MyRecordsView";
import AllRecordsView from "./AllRecordsView";
import ResourcesManagerView from "./ResourcesManagerView";
import ProfileDialog from "./ProfileDialog";
import ScannerDialog from "./ScannerDialog";

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  ${({ theme }) => `
    position: relative;
    flex: 1 1 auto;
    width: calc(100% - ${theme.spacing(5)}px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${theme.breakpoints.up(1280)} {
      width: 1200px;
    }
  `}
`;

const Header = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(2, 0)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    ${theme.breakpoints.up("md")} {
      margin: ${theme.spacing(5, 2)};
      justify-content: center;
    }
  `}
`;

const Title = styled.div`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 2.5rem;
    ${theme.breakpoints.down("md")} {
      font-size: 2rem;
    }
  `}
`;

const SpeedDialWrapper = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 72px;
    right: 8px;
    height: 128px;
    z-index: 1;
    ${theme.breakpoints.up("md")} {
      top: 64px;
      right: 40px;
    }
  `}
`;

const Nickname = styled.span`
  ${({ theme }) => `
    margin: ${theme.spacing(0, 1)};
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 2;
  `}
`;

const ProfileIconButton = styled(Icon)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
  `}
`;

const MainStyle = createGlobalStyle`
  & .MuiTooltip-tooltip {
    font-size: 1rem;
  }
`;

const Body = styled.div`
  ${({ theme }) => `
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    & > h4 {
      margin: ${theme.spacing(2, 2, 1)};
      font-size: 1.5rem;
      color: #2d2d2d;
    }
  `}
`;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface StyledTabProps {
  label: string;
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontSize: "1rem",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      "&$selected": {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

type Props = {
  auth: AuthStateType;
  logout: () => void;
  updateUser: (
    nickname: string,
    isEmailNotificationEnable: boolean,
    oldPassword?: string,
    newPassword?: string
  ) => void;
};

function Main(props: Props) {
  const { auth, logout, updateUser } = props;
  const [open, setOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [scannerOpen, setScannerOpen] = React.useState(false);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const actions = React.useMemo(() => {
    const actions = [
      {
        icon: <Icon>person</Icon>,
        name: "내 정보",
        action: () => {
          setProfileOpen(true);
        },
      },
    ];
    actions.push({
      icon: <Icon>exit_to_app</Icon>,
      name: "로그아웃",
      action: () => {
        logout();
      },
    });
    return actions;
  }, [auth, logout]);

  const tabs = React.useMemo(() => {
    const tabs: { name: string; component: any }[] = [
      {
        name: "자산 현황",
        component: ResourcesDashboardView,
      },
      {
        name: "나의 이력",
        component: MyRecordsView,
      },
    ];
    if (auth.user?.isStaff) {
      tabs.push({
        name: "자산 관리",
        component: ResourcesManagerView,
      });
      tabs.push({
        name: "자산 이력",
        component: AllRecordsView,
      });
      tabs.push({
        name: "회원 관리",
        component: UserManagerView,
      });
    }

    return tabs;
  }, [auth]);

  if (auth.user === null) {
    return <Root />;
  }

  return (
    <Root>
      <Content>
        <Header>
          <Title>
            자산<strong>관리</strong> 시스템
          </Title>
          <Spacer />
          <Hidden smDown>
            <ProfileIconButton
              style={{ color: "#000000", marginRight: 8 }}
              onClick={() => setScannerOpen(true)}
            >
              linked_camera
            </ProfileIconButton>
            <>
              {`안녕하세요, `}
              <Nickname onClick={() => setOpen((prev) => !prev)}>
                {auth.user?.nickname}
              </Nickname>
              {` 님!`}
            </>
          </Hidden>
          <Hidden mdUp>
            <ProfileIconButton onClick={() => setOpen((prev) => !prev)}>
              account_circle
            </ProfileIconButton>
          </Hidden>
        </Header>
        {auth.user && (
          <SpeedDialWrapper>
            <SpeedDial
              ariaLabel="인증 회원 스피드다이얼"
              hidden={true}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              direction="down"
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => {
                    action.action();
                    setOpen(false);
                  }}
                />
              ))}
            </SpeedDial>
          </SpeedDialWrapper>
        )}
        <Body>
          <AntTabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="Main Tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            {tabs.map(({ name }, index) => {
              return <AntTab key={name} label={name} {...a11yProps(index)} />;
            })}
          </AntTabs>

          <SwipeableViews
            axis="x"
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >
            {tabs.map(({ name, component: Component }, index) => {
              return (
                <TabPanel key={name} value={tabIndex} index={index}>
                  <Component />
                </TabPanel>
              );
            })}
          </SwipeableViews>
        </Body>

        <MainStyle />
      </Content>

      <ProfileDialog
        open={profileOpen}
        email={auth.user?.email}
        nickname={auth.user?.nickname}
        isEmailNotificationEnable={auth.user?.isEmailNotificationEnable}
        onClose={(
          nickname: string,
          isEmailNotificationEnable: boolean,
          oldPassword?: string,
          newPassword?: string
        ) => {
          updateUser(
            nickname,
            isEmailNotificationEnable,
            oldPassword,
            newPassword
          );
          setProfileOpen(false);
        }}
        onCancel={() => setProfileOpen(false)}
      />

      <ScannerDialog open={scannerOpen} onClose={() => setScannerOpen(false)} />
    </Root>
  );
}

function mapStateToProps(state: StateType) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logout,
      updateUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
